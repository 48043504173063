<template>
  <div class="register">
    <div class="header">
      <p @click="showRule=true">活动规则</p>
    </div>
    <div class="content">
      <div class="input-row">
        <img src="@/assets/mobile.png" alt="" srcset="">
        <input type="tel" v-model="mobile" placeholder="请输入手机号码">
      </div>
      <div class="input-row">
        <img src="@/assets/password.png" alt="" srcset="">
        <input type="password" v-model="password" placeholder="请设置登录密码">
      </div>
      <div class="input-row" @click="showPicker=true">
        <img src="@/assets/address.png" alt="" srcset="">
        <p>{{mainAddress}}<span>{{address?address:'请选择地区'}}</span></p>
      </div>
      <div class="code-row">
        <div class="code-input-row">
          <img src="@/assets/code.png" alt="" srcset="">
          <input type="tel" v-model="code" placeholder="请输入验证码">
        </div>
        <div class="code-btn" :style="remainTime>0?'background-color: #c4c4c4':'background-color: #ffa21b'" @click="sendCode">{{remainTime>0?`${remainTime}秒后获取`:'获取验证码'}}</div>
      </div>

      <div class="agreement">
        <img :src="agreeAgreement?select:unselect" alt="" srcset="" @click="agreeAgreement=!agreeAgreement">
        <p>我已阅读并同意<span @click="showAgreement=true">《用户注册协议》</span></p>
      </div>

      <div class="btn-register" @click="register">立即注册</div>
      <div class="btn-register -blue">立即下载</div>
    </div>

    <!-- 活动规则弹出层 -->
    <van-popup v-model="showRule">
      <div class="rule">
        <p class="title">
          <img src="@/assets/title-left.png" alt="" srcset="">
          <span>新用户可享福利</span>
          <img src="@/assets/title-right.png" alt="" srcset="">
        </p>
        <p v-html="ruleContent" class="detail"></p>
      </div>
    </van-popup>
    <!-- 用户注册协议 -->
    <van-popup v-model="showAgreement">
      <div class="rule">
        <p class="title">
          <img src="@/assets/title-left.png" alt="" srcset="">
          <span>用户注册协议</span>
          <img src="@/assets/title-right.png" alt="" srcset="">
        </p>
        <p v-html="agreementContent" class="detail"></p>
      </div>
    </van-popup>
    <!-- 地址选择弹出层 -->
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker title="请选择地区" show-toolbar :columns="columns" @cancel="showPicker=false" @confirm="onConfirm" />
    </van-popup>
  </div>
</template>

<script>
import select from "@/assets/select.png";
import unselect from "@/assets/unselect.png";
export default {
  data() {
    return {
      select: select,
      unselect: unselect,
      referrer: "",
      mobile: "",
      password: "",
      code: "",
      referrer: "",
      mainAddress: "",
      address: "",
      agreeAgreement: false,
      showRule: false,
      ruleContent: "",
      showAgreement: false,
      agreementContent: "",
      showPicker: false,
      columns: [],

      timeTerval: null,
      remainTime: 0,
    };
  },
  created() {
    this.referrer = this.$route.query.referrerCode;
    this.mainAddress = process.env.VUE_APP_MAIN_AREA;
    this.columns = process.env.VUE_APP_AREA_DATA;
    this.loadActiveRule();
    if (localStorage.dieLine) {
      this.countDown(localStorage.dieLine);
    }
  },
  methods: {
    loadActiveRule() {
      this.$http.all(
        [
          {
            type: "get",
            url: "/park-service-system/diction/selectDictionaryByType",
            data: { type: "give_coupon_desc" },
          },
          {
            type: "get",
            url: "/park-service-system/diction/selectDictionaryByType",
            data: { type: "user_service" },
          },
        ],
        (res) => {
          this.ruleContent = res[0].value;
          this.agreementContent = res[1].value;
        }
      );
    },
    countDown(dieLine) {
      this.timeTerval = setInterval(() => {
        let now = new Date().getTime();
        if (now > dieLine) {
          localStorage.removeItem("dieLine");
          clearInterval(this.timeTerval);
        } else {
          this.remainTime = ((dieLine - now) / 1000).toFixed(0);
        }
      }, 1000);
    },
    sendCode() {
      if (this.remainTime > 0) {
        return;
      }
      if (this.mobile) {
        this.$http.post(
          "/park-service-user/park/userLogin/sendCode",
          {
            codeType: "register",
            mobile: this.mobile,
          },
          (res) => {
            let time = new Date().getTime() + 30000;
            localStorage.dieLine = time;
            this.countDown(time);
            this.$toast("验证码发送成功，请注意查收");
          }
        );
      } else {
        this.$toast("请输入正确的手机号码");
      }
    },
    register() {
      if (!this.mobile) {
        this.$toast("请填写正确的手机号码");
        return;
      }
      if (!this.password) {
        this.$toast("请设置登录密码");
        return;
      }
      if (!this.address) {
        this.$toast("请选择所在地区");
        return;
      }
      if (!this.code) {
        this.$toast("请输入手机验证码");
        return;
      }
      if (!this.agreeAgreement) {
        this.$toast("注册新用户需同意《用户注册协议》");
        return;
      }
      this.$http.post(
        "/park-service-user/park/userLogin/register",
        {
          userName: "User_" + new Date().getTime(),
          sex: "1",
          mobile: this.mobile,
          password: this.password,
          code: this.code,
          address: this.mainAddress + this.address,
          referrer: this.referrer,
        },
        (res) => {
          this.$dialog
            .alert({
              title: "欢迎加入我们",
              message: `账号${this.mobile}注册成功，感谢您的信任，相关优惠券已经下发到您的账户，下载APP登录账户即可使用。我们将竭诚为您服务，祝您使用愉快、停车无忧！`,
            })
            .then(() => {});
        }
      );
    },
    onConfirm(value) {
      this.address = value;
      this.showPicker = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  height: 100%;
  overflow-y: auto;
  background-color: #d5ecfd;
  background-image: url("~@/assets/header.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  .header {
    font-size: 0;
    p {
      position: absolute;
      z-index: 9;
      right: 0;
      top: 0.7rem;
      font-size: 0.28rem;
      padding: 0.1rem;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      color: #008fff;
      background-color: #8ecbfa;
    }
  }
  .content {
    width: 6.63rem;
    padding: 0.6rem 0;
    margin: 4.5rem auto 0 auto;
    background-color: #fff;
    border-radius: 0.2rem;
    position: relative;
    top: -0.2rem;
    .input-row {
      width: 5.6rem;
      height: 0.78rem;
      background-color: #f8f8f8;
      border-radius: 0.2rem;
      font-size: 0.28rem;
      margin: 0 auto 0.35rem auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img {
        width: 0.44rem;
        height: 0.44rem;
        margin: 0 0.2rem;
      }
      input {
        border: 0;
        background-color: transparent;
        height: 100%;
        flex: 1;
        &::-webkit-input-placeholder {
          color: #c9c9c9;
        }
      }
    }
    .code-row {
      width: 5.6rem;
      background-color: transparent;
      margin: 0 auto 0.35rem auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .code-input-row {
        width: 3.6rem;
        height: 0.78rem;
        overflow: hidden;
        background-color: #f8f8f8;
        border-radius: 0.2rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 0.4rem;
        img {
          width: 0.44rem;
          height: 0.44rem;
          margin: 0 0.2rem;
        }
        input {
          flex: 1;
          border: 0;
          background-color: transparent;
          height: 100%;
          &::-webkit-input-placeholder {
            color: #c9c9c9;
          }
        }
      }
      .code-btn {
        flex: 1;
        height: 0.78rem;
        color: #fff;
        font-size: 0.28rem;
        line-height: 0.78rem;
        text-align: center;
        border-radius: 0.2rem;
      }
    }
    .agreement {
      width: 5.6rem;
      margin: 0 auto 0.35rem auto;
      display: flex;
      align-items: center;
      img {
        width: 0.44rem;
        height: 0.44rem;
        margin-right: 0.1rem;
      }
      p {
        color: #cacaca;
        font-size: 0.24rem;
        span {
          color: #6abeff;
        }
      }
    }
    .btn-register {
      width: 5.6rem;
      height: 0.78rem;
      margin: 0 auto 0.35rem auto;
      background: #ffa21b;
      border-radius: 0.2rem;
      color: #fff;
      font-size: 0.36rem;
      line-height: 0.78rem;
      text-align: center;
    }
    .-blue {
      background: #269bf6;
      margin: 0 auto;
    }
  }

  .rule {
    width: 5.4rem;
    padding: 0.6rem;
    border-radius: 0.2rem;
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #1f2020;
      font-size: 0.3rem;
      font-weight: bold;
      text-align: center;
      span {
        margin: 0 0.27rem;
      }
      img {
        width: 1.1rem;
        height: 0.07rem;
      }
    }
    .detail {
      margin-top: 0.27rem;
      text-indent: 2em;
    }
  }

  /deep/ .van-popup--center {
    border-radius: 0.2rem;
  }
}
</style>